import React, { Component } from "react"
import styled from "@emotion/styled"
import ConstructionIcon from "./assets/ConstructionIcon"
import "./App.css"
import SemiTruckLogo from "./assets/SemiTruckLogo"

class App extends Component {
  render() {
    return (
      <Wrapper>
        <SemiTruckLogo height={500} />
        <StyledTextBanner>Under Construction</StyledTextBanner>
        {/* <StyledArtCredit>
          Icons made by{" "}
          <a
            href="https://www.flaticon.com/authors/smalllikeart"
            title="smalllikeart"
          >
            smalllikeart
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>{" "}
          is licensed by{" "}
          <a
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
          >
            CC 3.0 BY
          </a>
        </StyledArtCredit> */}
      </Wrapper>
    )
  }
}

const Wrapper = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #131d52;
`

const StyledConstructionIcon = styled(ConstructionIcon)`
  fill: white;
`

const StyledTextBanner = styled("h1")`
  color: white;
  font-size: 70px;
  font-family: Helvetica;
  font-weight: 100;
  margin: 0px;
`

const StyledArtCredit = styled("div")`
  font-size: 10px;
  color: white;
`
export default App
