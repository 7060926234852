import React from "react"

const ConstructionIcon = props => (
  <svg {...props} viewBox="0 0 64 64">
    <g id="outline">
      <path
        width="500px"
        d="M55,28a1,1,0,0,0,1-1V17a1,1,0,0,0-1-1H51V12.46a6,6,0,1,0-8,0V16H21V12.46a6,6,0,1,0-8,0V16H9a1,1,0,0,0-1,1V27a1,1,0,0,0,1,1h4v2H9a1,1,0,0,0-1,1V41a1,1,0,0,0,1,1H12.9l-1.89,18.9A1,1,0,0,0,12,62h6a1,1,0,0,0,1-.9L19.7,54H44.3l.71,7.1A1,1,0,0,0,46,62h6A1,1,0,0,0,53,60.9L51.105,42H55a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1H51V28ZM47,4a4,4,0,1,1-4,4A4,4,0,0,1,47,4Zm2,9.65V16H45V13.65a5.889,5.889,0,0,0,4,0ZM43.434,18l4.8,8H44.566l-4.8-8ZM49,28v2H45V28ZM25.434,40H21.766l4.8-8h3.668Zm7.132-8h3.668l-4.8,8H27.766ZM43.1,42l.6,6H20.305l.6-6Zm-5.661-2H33.766l4.8-8h3.668ZM21,30V28H43v2ZM37.434,18l4.8,8H38.566l-4.8-8Zm-6,0,4.8,8H32.566l-4.8-8Zm-6,0,4.8,8H26.566l-4.8-8Zm-1.2,8H20.566l-4.8-8h3.668ZM15,16V13.65a5.889,5.889,0,0,0,4,0V16ZM17,4a4,4,0,1,1-4,4A4,4,0,0,1,17,4ZM10,26V18h3.434l4.8,8Zm9,2v2H15V28ZM10,40V32h8.234l-4.8,8ZM17.1,60h-3.99l1.8-18H18.9Zm2.339-20H15.766l4.8-8h3.668Zm.481,12,.2-2H43.9l.2,2ZM43.434,40H39.766l4.8-8h3.668Zm3.471,20-1.8-18H49.1l1.8,18ZM54,32v8H45.766l4.8-8Zm-3.434-6-4.8-8H54v8Z"
      />
    </g>
  </svg>
)

export default ConstructionIcon
