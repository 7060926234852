import React from "react"
import styled from "@emotion/styled"

const Svg = styled("svg")`
  fill: #fff;
  color: #fff;
  stroke: #fff;
`

const SemiTruckLogo = props => (
  <Svg viewBox="0 0 600 476" {...props}>
    <g fill="none" stroke="#fff">
      <path d="M449.12 337.4l2.8 1.4.28 48.16-5.88 1.12m-136.64 0H150.64l-5.32-.28v-47.88l4.2-2.8 156.66.146" />
      <path d="M153.44 388.36s-.84 25.76 9.8 35.84l25.76-.56s7.56-4.76 8.4-30.52h22.4s10.92-.84 12.6-5.32M153.44 337.12l-.28-5.04h-8.12s2.24-32.2 5.88-35.84l-5.04-2.24-.84-.84v-16.24l4.2-2.52 12.04-1.68.28 23.52h-10.64M161.28 272.72l2.8-1.12 50.96-.28 5.6 5.04-.28 20.44-57.612.712-1.188-1.272" />
      <path
        d="M187.397 276.525L190.07 279l-.099 14.057-23.857.198.198-16.928zM166.032 278.906l24.038.094M192.446 276.327l.297 17.324 23.66-.099.099-14.552-2.475-2.574zM192.446 278.976l24.056.024"
        strokeWidth={0.5}
      />
      <path d="M153.16 332.08l72.944.18V211.682s.835-16.647 19.453-16.431c1.965.023 4.227-.04 6.707-.002M245.507 195.25H309.4" />
      <path d="M224 332.08l-.28-8.4h-46.9l4.9 8.4" />
      <circle r={9.881} cy={124.667} cx={261.743} strokeWidth={0.8} />
      <circle r={9.881} cy={124.667} cx={287.877} strokeWidth={0.8} />
      <path d="M197.4 393.12v-4.9M185.08 297.5v2.52l40.74 3.92M220.36 296.8l5.32.14M183.12 271.32l.099-62.6c-.253 1.023 20.814 1.833 36.946-.204" />
      <path
        d="M226.698 208.912s-.198-4.554-6.731-5.742c-6.534-1.188-6.732-.99-6.732-.99s-15.84-1.683-21.185-2.376c-4.048-.525-4.455-4.603-4.653-6.187"
        strokeWidth={2}
      />
      <path d="M194.39 149.73l.07-17.57s-1.094-11.108 8.2-16.361m8.812-2.552a33.063 33.063 0 0 1 2.168-.127l37.52-.42 5.11-5.74 43.586-.036M202.544 23.99l.198 136.414M210.556 23.812l1.095 136.295M202.369 24.374c1.624 1.722 6.191 1.972 8.152.068M236.598 196.636h-4.158l-1.386-.99.396-30.886s-.594-4.356 3.564-4.356 64.743-1.386 64.743-1.386v36.232" />
      <path d="M226.896 206.734l.792-51.082s-.792-14.057 12.078-13.859" />
      <path d="M227.738 158.276s0-4.505 5.494-5.148l65.337-2.03" />
      <circle r={2.574} cy={137.437} cx={244.716} strokeWidth={0.5} />
      <circle r={2.574} cy={134.269} cx={298.569} strokeWidth={0.5} />
      <circle r={2.574} cy={134.665} cx={277.582} strokeWidth={0.5} />
      <path
        d="M227.688 152.88l-34.058-.64s-4.6-.197-5.985 4.356"
        strokeWidth={2}
      />
      <path d="M179.428 156.89c5.332-.414 10.997-.646 17.374.247l-.792 35.143s-7.177 3.07-17.077-.297zM202.791 160.008c2.268.383 5.334.557 8.91-.297M220.165 208.516v0l-.594-45.241c-8.877.626-13.642 1.299-22.658.39" />
      <path d="M196.703 163.176l.262 45.7c7.728.318 15.457.207 23.185-.871M194.39 149.73l.135 7.11M220.165 208.516l.475 67.844" />
      <path
        d="M183.108 210.2a9.958 9.958 0 0 1-4.521-8.349 9.962 9.962 0 0 1 4.957-8.618m9.682-.188c1.487.791 2.75 1.947 3.668 3.348"
        strokeWidth={0.8}
      />
      <path d="M226.104 332.26s1.188 1.98 2.772 1.98c1.28 0 51.747-.259 71.128-.358M270.652 218.811v102.163h25.739v-102.08zM266.098 218.613l.28 102.36s-16.317.035-21.86-.163c-5.544-.198-5.824-4.588-5.824-4.588s-.314-84.344-.314-91.273c0-6.93 6.336-6.336 6.336-6.336zM215.362 163.048c1.806.117 2.255-.038 4.209.227M202.791 160.008l-4.294 3.731M211.7 159.711l3.785 3.45M197.018 163.045c.937-.22 1.619-.274 2.87-.396M182.873 209.115c4.676-1.078 9.621-1.49 14.096-1.56" />
      <path
        d="M248.115 142.555h-6.825l-.07-6.405c.383-3.547 6.455-3.565 6.86-.14z"
        strokeWidth={0.4}
      />
      <circle r={2.574} cy={134.699} cx={277.563} strokeWidth={0.5} />
      <path
        d="M280.936 141.217l-6.799.035-.07-7.84c.383-3.548 6.455-3.565 6.86-.14z"
        strokeWidth={0.4}
      />
      <circle r={2.574} cy={134.279} cx={298.563} strokeWidth={0.5} />
      <path
        d="M302.012 140.98h-6.924l-.021-7.988c.383-3.548 6.455-3.565 6.86-.14z"
        strokeWidth={0.4}
      />
      <path d="M239.766 141.793c.512-.03 1.025-.058 1.538-.087m6.923-.366c8.647-.432 17.294-.765 25.942-1.003m6.978-.172c4.597-.102 9.3-.173 14.056-.214m6.88-.041c18.838-.058 38.113.308 54.637.93" />
    </g>
    <g fill="none" stroke="#fff">
      <path d="M447.686 337.12l-299.6.28-2.8 1.4-.28 48.16 5.88 1.12h295.68l5.32-.28v-47.88z" />
      <path d="M443.766 388.36s.84 25.76-9.8 35.84l-25.76-.56s-7.56-4.76-8.4-30.52h-22.4s-10.92-.84-12.6-5.32M443.766 337.12l.28-5.04h8.12s-2.24-32.2-5.88-35.84l5.04-2.24.84-.84v-16.24l-4.2-2.52-12.04-1.68-.28 23.52h10.64M435.926 272.72l-2.8-1.12-50.96-.28-5.6 5.04.28 20.44 57.613.712 1.187-1.272" />
      <path
        d="M409.809 276.525L407.136 279l.1 14.057 23.857.198-.198-16.928zM431.175 278.906l-24.039.094M404.76 276.327l-.297 17.324-23.66-.099-.099-14.552 2.475-2.574zM404.76 278.976l-24.056.024"
        strokeWidth={0.5}
      />
      <path d="M444.046 332.08l-72.944.18V211.682s-.835-16.647-19.453-16.431c-1.964.023-4.226-.04-6.706-.002M351.699 195.25H246.566" />
      <path d="M373.206 332.08l.28-8.4h46.9l-4.9 8.4" />
      <circle
        transform="scale(-1 1)"
        r={9.881}
        cy={124.667}
        cx={-335.464}
        strokeWidth={0.8}
      />
      <circle
        transform="scale(-1 1)"
        r={9.881}
        cy={124.667}
        cx={-309.329}
        strokeWidth={0.8}
      />
      <path d="M399.806 393.12v-4.9M412.126 297.5v2.52l-40.74 3.92M376.846 296.8l-5.32.14M414.086 271.32l-.099-62.6c.253 1.023-20.814 1.834-36.945-.204" />
      <path
        d="M370.508 208.912s.198-4.554 6.732-5.742c6.533-1.188 6.731-.99 6.731-.99s15.84-1.683 21.185-2.376c4.048-.525 4.455-4.603 4.653-6.187"
        strokeWidth={2}
      />
      <path d="M402.816 149.73l-.07-17.57s1.094-11.108-8.2-16.361m-8.812-2.552a33.063 33.063 0 0 0-2.168-.127l-37.52-.42-5.11-5.74-64.938-.054M394.663 23.99l-.198 136.414M386.65 23.812l-1.095 136.295M394.838 24.374c-1.625 1.722-6.192 1.972-8.153.068M360.608 196.636h4.158l1.386-.99-.396-30.886s.594-4.356-3.564-4.356c-4.157 0-64.742-1.386-64.742-1.386v36.232" />
      <path d="M370.31 206.734l-.792-51.082s.792-14.057-12.077-13.859" />
      <path d="M369.469 158.276s0-4.505-5.495-5.148l-64.815-2.011-.59-.018" />
      <circle
        transform="scale(-1 1)"
        r={2.574}
        cy={137.437}
        cx={-352.491}
        strokeWidth={0.5}
      />
      <circle
        transform="scale(-1 1)"
        r={2.574}
        cy={134.665}
        cx={-319.625}
        strokeWidth={0.5}
      />
      <path
        d="M369.518 152.88l34.058-.64s4.6-.197 5.986 4.356"
        strokeWidth={2}
      />
      <path d="M417.778 156.89c-5.332-.414-10.996-.646-17.374.247l.792 35.143s7.178 3.07 17.077-.296zM394.415 160.008a22.161 22.161 0 0 1-8.91-.297M377.042 208.516v0l.594-45.241c8.876.626 13.641 1.299 22.657.391" />
      <path d="M400.503 163.176l-.262 45.7c-7.728.318-15.456.207-23.185-.871M402.816 149.73l-.135 7.11M377.042 208.516l-.476 67.844" />
      <path
        d="M414.099 210.2a9.958 9.958 0 0 0 4.52-8.349 9.962 9.962 0 0 0-4.956-8.618m-9.683-.187a10.014 10.014 0 0 0-3.668 3.347"
        strokeWidth={0.8}
      />
      <path d="M371.102 332.26s-1.188 1.98-2.772 1.98c-1.286 0-52.301-.262-71.444-.36M326.554 218.811v102.163h-25.738v-102.08zM331.108 218.613l-.28 102.36s16.317.035 21.86-.163c5.545-.198 5.825-4.588 5.825-4.588s.314-84.344.314-91.273c0-6.93-6.336-6.336-6.336-6.336zM381.844 163.048c-1.806.117-2.255-.038-4.208.227M394.415 160.008l4.294 3.731M385.506 159.711l-3.784 3.45M400.188 163.045c-.937-.22-1.618-.274-2.87-.396M414.334 209.115c-4.676-1.078-9.622-1.49-14.096-1.56" />
      <path
        d="M349.091 142.555h6.825l.07-6.405c-.382-3.547-6.455-3.565-6.86-.14z"
        strokeWidth={0.4}
      />
      <circle
        transform="scale(-1 1)"
        r={2.574}
        cy={134.699}
        cx={-319.644}
        strokeWidth={0.5}
      />
      <path
        d="M316.27 141.217l6.8.035.07-7.84c-.383-3.548-6.456-3.565-6.86-.14z"
        strokeWidth={0.4}
      />
      <path d="M357.44 141.793c-.512-.03-1.025-.058-1.537-.087m-6.924-.366c-8.647-.432-17.294-.765-25.941-1.003m-6.978-.172c-4.597-.102-9.3-.173-14.056-.214" />
    </g>
  </Svg>
)

export default SemiTruckLogo
